var stopPaceAfterInterval = function() {
    var counter = 0;
    var iterations = 0;

    var refreshIntervalId = setInterval( function(){
        var progress;

        if( typeof $( '.pace-progress' ).attr( 'data-progress-text' ) !== 'undefined' ) {
            progress = Number( $( '.pace-progress' ).attr( 'data-progress-text' ).replace('%', '') );
        }

        if( progress === 99 ) {
            counter++;
        }

        if( counter > 20 ) {
            clearInterval(refreshIntervalId);
            Pace.stop();
            document.body.className = document.body.className.replace('pace-running', '');
        }

        if (iterations > 150) {
            clearInterval(refreshIntervalId);
        }

        iterations++;
    }, 200);
};
stopPaceAfterInterval();