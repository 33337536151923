(function () {
  'use strict';

  $.fullCalendar.DayGrid.mixin({

    limitRow: function (row, levelLimit) {
      var _this = this;
      var rowStruct = this.rowStructs[row];
      var moreNodes = []; 
      var col = 0; 
      var levelSegs; 
      var cellMatrix; 
      var limitedNodes; 
      var i, seg;
      var segsBelow; 
      var totalSegsBelow; 
      var colSegsBelow; 
      var td, rowspan;
      var segMoreNodes; 
      var j;
      var moreTd, moreWrap, moreLink;

      function emptyCellsUntil(endCol) { 
        while (col < endCol) {
          segsBelow = _this.getCellSegs(row, col, levelLimit);
          if (segsBelow.length) {
            td = cellMatrix[levelLimit - 1][col];
            moreLink = _this.renderMoreLink(row, col, segsBelow);
            moreWrap = $('<div/>').append(moreLink);
            td.append(moreWrap);
            moreNodes.push(moreWrap[0]);
          }
          col++;
        }
      }

      if (levelLimit && levelLimit <= rowStruct.segLevels.length) { 
        levelSegs = rowStruct.segLevels[levelLimit - 1];
        cellMatrix = rowStruct.cellMatrix;

        limitedNodes = rowStruct.tbodyEl.children().slice(levelLimit) 
          .addClass('fc-limited').get(); 

        for (i = 0; i < levelSegs.length; i++) {
          seg = levelSegs[i];
          emptyCellsUntil(seg.leftCol); 

          colSegsBelow = [];
          totalSegsBelow = 0;
          while (col < seg.rightCol) {
            segsBelow = this.getCellSegs(row, col, levelLimit);
            colSegsBelow.push(segsBelow);
            totalSegsBelow += segsBelow.length;
            col++;
          }

          if (totalSegsBelow) { 
            td = cellMatrix[levelLimit - 1][seg.leftCol]; 
            rowspan = td.attr('rowspan') || 1;
            segMoreNodes = [];

            for (j = 0; j < colSegsBelow.length; j++) {
              moreTd = $('<td class="fc-more-cell"/>').attr('rowspan', rowspan);
              segsBelow = colSegsBelow[j];
              moreLink = this.renderMoreLink(
                row,
                seg.leftCol + j,
                [seg].concat(segsBelow) 
              );
              moreWrap = $('<div/>').append(moreLink);
              moreTd.append(moreWrap);
              segMoreNodes.push(moreTd[0]);
              moreNodes.push(moreTd[0]);
            }

            td.addClass('fc-limited').after($(segMoreNodes)); 
            limitedNodes.push(td[0]);
          }
        }

        emptyCellsUntil(this.colCnt); 
        rowStruct.moreEls = $(moreNodes); 
        rowStruct.limitedEls = $(limitedNodes); 
      }
    }
  });

})();
